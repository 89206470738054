import React, { FC } from "react";

import { useResizeImgWithContainerWidth } from "./hooks";

import { SanityImage } from "components/SanityImage";
import type { Crop, Hotspot } from "interface/sanityNode";
export interface ArticleImgProps {
  _ref: string;
  alt: string;
  crop?: Crop;
  hotspot?: Hotspot;
  loading?: "lazy" | "eager";
  className?: string;
}

const ArticleImg: FC<ArticleImgProps> = ({
  _ref,
  alt,
  crop,
  hotspot,
  loading = "lazy",
  className,
}) => {
  const { containerRef, width, quality } = useResizeImgWithContainerWidth(_ref);
  return (
    <div className={`flex w-full flex-col items-center ${className}`} ref={containerRef}>
      {width ? (
        <SanityImage
          width={width}
          crop={crop}
          hotspot={hotspot}
          _ref={_ref}
          alt={alt || ""}
          className="h-auto w-full object-contain"
          loading={loading}
          config={{
            quality,
          }}
        />
      ) : null}
      {alt !== "" ? (
        <span className="mt-3 mb-4 text-sm text-btq-sub-text-normal md:mb-6 md:text-sm xl:mt-5 xl:mb-9 xl:text-lg xl:leading-7">
          {alt}
        </span>
      ) : null}
    </div>
  );
};

export { ArticleImg };
