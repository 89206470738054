import React, { ElementType, PropsWithChildren } from "react";
import {
  PortableText as PortableTextCompoent,
  PortableTextReactComponents,
} from "@portabletext/react";

import { CustomLink } from "components/CustomLink";
import { ArticleImg } from "../ArticleImg";
import { Block } from "interface/sanityNode";

const NormalTextStyle =
  "mb-6 leading-relaxed md:text-lg lg:text-xl xl:mb-9 md:leading-loose xl:leading-relaxed";

type ListProps = PropsWithChildren<{ as: ElementType }>;

const List = ({ as: Tag, children }: ListProps) => (
  <Tag className={`${NormalTextStyle} list-inside ${Tag === "ul" ? "list-disc" : "list-decimal"}`}>
    {children}
  </Tag>
);

const ArticlePostPortableTextComponent: Partial<PortableTextReactComponents> = {
  block: {
    h3: ({ children }) => (
      <h3 className="my-1 font-semibold text-primary md:my-5 md:text-2xl">{children}</h3>
    ),
    h4: ({ children }) => (
      <h4 className="my-1 font-semibold text-primary md:my-2 md:text-base xl:my-4 xl:text-xl">
        {children}
      </h4>
    ),
    normal: ({ children }) => <p className={`${NormalTextStyle}`}>{children}</p>,
  },
  types: {
    articleContentImg: ({ value }) => <ArticleImg _ref={value.asset._ref} alt={value.alt || ""} />,
  },
  marks: {
    strong: ({ children }) => <span className="font-bold text-primary">{children}</span>,
    link: ({ children, value }) => (
      <CustomLink href={value.href}>
        <span className="text-btq-sub-text-light underline transition-colors duration-300 hover:text-primary">
          {children}
        </span>
      </CustomLink>
    ),
  },
  list: {
    bullet: ({ children }) => <List as="ul">{children}</List>,
    number: ({ children }) => <List as="ol">{children}</List>,
  },
};

interface PortableTextProps {
  value: Array<Block>;
}

const PortableText = ({ value }: PortableTextProps) => (
  <PortableTextCompoent value={value} components={ArticlePostPortableTextComponent} />
);

export { PortableText };
