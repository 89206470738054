import { useCallback, useRef, useState } from "react";
import { parseImageRef } from "gatsby-plugin-sanity-image";

import { useDebounceResizing } from "hooks/useDebounceResizing";
import { screensWithoutPx as screens } from "hooks/useBreakpoint";

const qualityValues = {
  [screens["2xl"]]: 75,
  [screens.xl]: 75,
  [screens.lg]: 80,
  [screens.md]: 85,
  [screens.sm]: 85,
  [screens.xs]: 90,
  [screens.xxs]: 95,
};

const MAX_SECTION_WIDTH = 1240;

export const useResizeImgWithContainerWidth = (_ref: string) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [quality, setQuality] = useState<number>(75);

  const resizeImg = useCallback(
    (breakpoint: number) => {
      if (!containerRef.current) {
        return;
      }

      const { width: w } = parseImageRef(_ref).dimensions;
      const maxWidth = w > MAX_SECTION_WIDTH ? MAX_SECTION_WIDTH : w;
      const containerWidth = containerRef.current.offsetWidth;

      const isDesktop = breakpoint >= screens.xl;
      const updatedWidth = isDesktop ? maxWidth : containerWidth;

      setWidth(updatedWidth);
      setQuality(qualityValues[breakpoint]);
    },
    [_ref]
  );

  useDebounceResizing(resizeImg);

  return { containerRef, width, quality };
};
