import React, { FC } from "react";
import { ArticleImg, ArticleImgProps } from "../ArticleImg";

interface BannerProps extends ArticleImgProps {}

const Banner: FC<BannerProps> = (banner) => (
  <ArticleImg {...banner} className="mb-4 md:mb-6 xl:mb-10" />
);

export { Banner };
