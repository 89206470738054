import React, { useEffect, useState } from "react";
import type { PageProps } from "gatsby";

import withI18n from "i18n/withI18n";
import SEO from "components/seo";
import { Layout } from "components/Layout";
import { Section } from "components/Section";
import { CustomLink } from "components/CustomLink";

import { Banner } from "views/articlePost/Banner";
import { PortableText } from "views/articlePost/PortableText";

import IconBack from "assets/ic_back_to_page.inline.svg";

import { SLUG } from "constants/slug";
import { BlogPostData } from "utils/cms/article";
import { getPrevLocation } from "utils/url/prevLocation";

const BackButton = () => {
  const [backHref, setBackHref] = useState<string>(SLUG.NEWSROOM);

  // since rendering will run before onRouteUpdate
  useEffect(() => setBackHref(() => getPrevLocation() || SLUG.NEWSROOM), []);

  // Hide icon on mobile util we replace the icon with something without padding
  return (
    <CustomLink href={backHref} className="hidden md:block">
      <IconBack />
    </CustomLink>
  );
};
interface ArticlePostPageContext {
  data: BlogPostData;
  thumbImgUrl: string;
}

const ArticlePostPage = ({ pageContext, location }: PageProps) => {
  const { data, thumbImgUrl } = pageContext as ArticlePostPageContext;
  const { title, localDate, isoDate, author, paragraphBlock, bannerImg } = data;

  return (
    <>
      <SEO
        title={title}
        pathname={location.pathname}
        titlePrefix
        article={{ publishedTime: isoDate }}
        previewImg={thumbImgUrl}
        locale
      />
      <Layout>
        <Section>
          <div className="flex flex-col items-start pt-24 pb-10 xl:px-0 xl:pt-40 xl:pb-20">
            <BackButton />
            <h1 className="mb-3 text-2xl font-semibold sm:text-3xl md:mt-9 md:text-5xl lg:mb-6">
              {title}
            </h1>
            <span className="mb-2 text-sm text-btq-sub-text-normal md:mb-6 md:text-lg xl:mb-9 xl:text-xl xl:leading-6">
              {`${localDate} • ${author}`}
            </span>

            {bannerImg ? (
              <Banner
                _ref={bannerImg.mainImg}
                alt=""
                crop={bannerImg?.crop}
                hotspot={bannerImg?.hotspot}
                loading="eager"
              />
            ) : null}

            {paragraphBlock ? (
              <div className="mx-auto w-[min((100%-1rem),45ch)] md:w-[min((100%-2rem),60ch)] xl:w-85ch">
                <PortableText value={paragraphBlock} />
              </div>
            ) : null}
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default withI18n(ArticlePostPage);
